import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler, isDevMode, Component } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { ComponentsModule } from 'src/components/components.module';
import { TokenInterceptor } from '../inteceptors/token.interceptor';
import { AuthService } from './../../src/services/auth.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { HierarchyPageModule } from 'src/pages/hierarchy/hierarchy.module';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { NoticePageModule } from 'src/pages/notice/notice.module';
import { SharedModule } from 'src/shared/shared.module';
import { AnalyticsProvider } from 'src/shared/analytics/analytics';
import { MTAErrorHandler } from 'src/shared/MTAErrorHandler';
import { CameraProvider } from 'src/shared/services/camera.provider';
import { File } from '@ionic-native/file/ngx';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedPlatformProvider } from 'src/shared/platform';

import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicSelectableComponent } from 'ionic-selectable';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const isIE = window.navigator.userAgent.indexOf('MSIE') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log( message );
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({ 
		auth: {
      clientId: environment.adalConfig.clientId,
      authority: environment.adalConfig.authority,
      redirectUri: '/',
      postLogoutRedirectUri: '/',	
		},
		cache: { 
      cacheLocation: BrowserCacheLocation.LocalStorage, 
			storeAuthStateInCookie: isIE,  // set to true for IE 11. Remove this line to use Angular Universal
		},
		system: { 
			allowRedirectInIframe: true, 
			loggerOptions: { 
				loggerCallback, 
				logLevel: LogLevel.Info, 
				piiLoggingEnabled: false, 
			}, 
		}, 
	}); 
} 

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ["openid", "offline_access", environment.scope]
	);
	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
	};
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			scopes: ["openid", "offline_access", environment.scope],
		},
		loginFailedRoute: "/login-failed",
	};
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ComponentsModule,
        HierarchyPageModule,
        NoticePageModule,
        IonicSelectableComponent,
        SharedModule,
        MsalModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
    ],
    providers: [

        AuthService,
        SharedPlatformProvider,
     
        HttpClient,      
        InAppBrowser,
        AnalyticsProvider,
        CameraProvider,        
        File,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: ErrorHandler, useClass: MTAErrorHandler },        
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
    exports:[TranslateModule,IonicSelectableComponent]
})
export class AppModule { }
